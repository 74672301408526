import React from 'react';

import {
  useNavigate,
  generatePath,
} from 'react-router-dom';

import {
  BasePage,
  FlexBox,
  PrimaryButton,
} from 'tt-mod-frontend';

import {
  Routes,
} from 'enums';

import {
  usePermissionsApi,
  useTemplateApi,
} from 'hooks';

import {
  TemplatesTable,
} from 'ui/components';

const TableSchema = [{
  key: 'id',
  heading: 'Id',
}, {
  key: 'group',
  heading: 'Group Name',
}, {
  key: 'templateNames',
  heading: 'Templates',
}];

const CellRenderers = {
  id: id => (

    <FlexBox
      sx={{
        color: '#1976d2',
      }}>

      {id}
    </FlexBox>
  ),
  templateNames: names => (

    <FlexBox
      sx={{
        display: 'block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '600px',
      }}>

      {names}
    </FlexBox>
  ),
};

export const TemplatePermissions = () => {

  const navigate = useNavigate();

  const {
    templates,
    templatesError,
    templatesLoading,
    refetchTemplates,
  } = useTemplateApi({
    runApi: {
      templates: true,
    },
  });

  const {
    groupPermissions,
    groupPermissionsError,
    groupPermissionsLoading,
    refetchGroupPermissions,
  } = usePermissionsApi({
    runApi: {
      groupPermissions: true,
    },
  });

  const tableData = React.useMemo(() => {

    const data = [];

    if (!Array.isArray(templates) || !Array.isArray(groupPermissions)) {
      return data;
    }

    for (const item of groupPermissions) {

      data.push({
        id: item.id,
        group: item.group,
        templateNames: templates
          .filter(template => item.templateIds.includes(template.id))
          .map(template => template.name)
          .join(', '),
      });
    }

    return data;
  }, [
    templates,
    groupPermissions,
  ]);

  const onRowClick = React.useCallback(row => {

    navigate(generatePath(Routes.TemplatePermissionsEditor, {
      groupPermissionsId: row.id,
    }))
  }, [
    navigate,
  ]);

  const newPermissionsButton = React.useMemo(() => {

    const onNewPermissionsClick = () => {
      navigate(generatePath(Routes.TemplatePermissionsEditor, {
        groupPermissionsId: undefined,
      }));
    };

    return (

      <PrimaryButton
        onClick={onNewPermissionsClick}>

        New Permissions
      </PrimaryButton>
    );
  }, [
    navigate,
  ]);

  const onErrorRetry = React.useCallback(() => {

    if (templatesError) {
      refetchTemplates();
    }

    if (groupPermissionsError) {
      refetchGroupPermissions();
    }
  }, [
    templatesError,
    groupPermissionsError,
    refetchTemplates,
    refetchGroupPermissions,
  ]);

  return (

    <BasePage
      heading={'Template Permissions'}
      subHeading={'Manage the list of templates each user group has access to.'}
      rightComponent={newPermissionsButton}
      loading={groupPermissionsLoading || templatesLoading}
      error={groupPermissionsError || templatesError}
      onErrorRetry={onErrorRetry}>

      <FlexBox
        sx={{
          flex: '1 1 0px',
          overflow: 'auto',
        }}>

        <TemplatesTable
          schema={TableSchema}
          cellRenderers={CellRenderers}
          data={tableData}
          onRowClick={onRowClick}/>
      </FlexBox>
    </BasePage>
  );
};

TemplatePermissions.displayName = 'TemplatePermissions';