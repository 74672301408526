import * as ImagesModule from 'assets/images';

type ImageEnum = {
  [key in keyof typeof ImagesModule]: string
}

const Images: ImageEnum = Object.entries(ImagesModule).reduce((acc: any, curr) => {
  const [key, value] = curr;
  acc[key] = value.toString();
  return acc;
}, {});

export const Assets = {
  Images,
};