import React from 'react';

import {
  TextField,
  Checkbox,
} from '@mui/material';

import {
  useParams,
  useNavigate,
  generatePath,
} from 'react-router-dom';

import {
  BasePage,
  CollapsibleCard,
  FlexBox,
  Paragraph,
  PrimaryButton,
  useModal,
} from 'tt-mod-frontend';

import {
  usePermissionsApi,
  useTemplateApi,
} from 'hooks';

import {
  TemplatesTable,
} from 'ui/components';
import { Routes } from 'enums';

const TableSchema = [{
  key: 'allowed',
  heading: 'Allowed',
}, {
  key: 'id',
  heading: 'Id',
}, {
  key: 'name',
  heading: 'Template Name',
}, {
  key: 'description',
  heading: 'Description',
}, {
  key: 'folderPath',
  heading: 'Group Path',
}];

export const TemplatePermissionsEditor = () => {

  const {
    groupPermissionsId,
  } = useParams();

  const navigate = useNavigate();

  const {
    showInformationModal,
    showErrorModal,
  } = useModal();

  const [
    state,
    setState,
  ] = React.useState({
    groupName: '',
    groupDescription: '',
    allowedTemplates: undefined,
    submitLoading: false,
  });

  const {
    groupPermissionsDetail,
    groupPermissionsDetailLoading,
    groupPermissionsDetailError,
    createGroupPermissions,
    updateGroupPermissions,
  } = usePermissionsApi({
    runApi: {
      groupPermissionsDetail: !!groupPermissionsId,
    },
    groupPermissionsId,
  });

  const {
    templates,
    templatesLoading,
    templatesError,
  } = useTemplateApi({
    runApi: {
      templates: true,
    },
  });

  const {
    tableData,
    tableCellRenderers,
    onTableRowClick,
  } = React.useMemo(() => {

    if (!Array.isArray(templates)) {
      return {
        tableData: [],
        tableCellRenderers: {},
        onTableRowClick: () => {},
      };
    }

    const tableData = [];
    const tableCellRenderers = {
      allowed: isAllowed => (

        <Checkbox
          checked={isAllowed}/>
      ),
    };

    for (const template of templates) {
      tableData.push({
        allowed: Array.isArray(state.allowedTemplates) && state.allowedTemplates.includes(template.id),
        id: template.id,
        name: template.name,
        description: template.description,
        folderPath: template.folderPath,
      });
    }

    const onTableRowClick = row => {

      setState(prevState => {

        const result = {
          ...prevState,
        };

        if (!row.allowed) {
          result.allowedTemplates = (Array.isArray(prevState.allowedTemplates) && [...prevState.allowedTemplates, row.id]) || [row.id]
          return result;
        }

        const allowedTemplates = [...prevState.allowedTemplates]
        allowedTemplates.splice(allowedTemplates.indexOf(row.id), 1);
        result.allowedTemplates = allowedTemplates;

        return result;
      });
    };

    return {
      tableData,
      tableCellRenderers,
      onTableRowClick,
    };
  }, [
    state.allowedTemplates,
    templates,
  ]);

  const onSaveChangesClick = React.useCallback(async () => {

    setState(prevState => ({
      ...prevState,
      submitLoading: true,
    }));

    try {

      const response = await updateGroupPermissions({
        id: groupPermissionsId,
        groupPermissionsData: {
          group: state.groupName,
          description: state.groupDescription,
          templateIds: state.allowedTemplates ?? [],
        },
      });

      setState(prevState => ({
        ...prevState,
        submitLoading: false,
      }));

      if (response.status !== 200) {

        showErrorModal({
          message: response.data?.errorMessage || 'An unknown error has occurred. Please try again.',
        });

        return;
      }

      showInformationModal({
        message: 'Permissions updated successfully.',
      });
    } catch (error) {

      setState(prevState => ({
        ...prevState,
        submitLoading: false,
      }));

      showErrorModal({
        message: error?.errorMessage || error?.message || 'An unknown error has occurred. Please try again.',
      });
    }
  }, [
    groupPermissionsId,
    state.groupName,
    state.groupDescription,
    state.allowedTemplates,
    updateGroupPermissions,
    showInformationModal,
    showErrorModal,
  ]);

  const onCreatePermissionsClick = React.useCallback(async () => {

    setState(prevState => ({
      ...prevState,
      submitLoading: true,
    }));

    try {

      const response = await createGroupPermissions({
        groupPermissionsData: {
          group: state.groupName,
          description: state.groupDescription,
          templateIds: state.allowedTemplates ?? [],
        },
      });

      setState(prevState => ({
        ...prevState,
        submitLoading: false,
      }));

      if (response.status !== 200) {

        showErrorModal({
          message: response.data?.errorMessage || 'An unknown error has occurred. Please try again.',
        });

        return;
      }

      showInformationModal({
        message: 'Permissions created successfully.',
      });

      navigate(generatePath(Routes.TemplatePermissionsEditor, {
        groupPermissionsId: response.data.id,
      }), {
        replace: true,
      });
    } catch (error) {

      setState(prevState => ({
        ...prevState,
        submitLoading: false,
      }));

      showErrorModal({
        message: error?.errorMessage || error?.message || 'An unknown error has occurred. Please try again.',
      });
    }
  }, [
    state.groupName,
    state.groupDescription,
    state.allowedTemplates,
    navigate,
    createGroupPermissions,
    showErrorModal,
    showInformationModal,
  ]);

  const button = React.useMemo(() => {

    const onClick = !!groupPermissionsId
      ? onSaveChangesClick
      : onCreatePermissionsClick;

    return (

      <PrimaryButton
        onClick={onClick}
        disabled={state.submitLoading}>

        { (!groupPermissionsId && 'Create Permissions') || 'Save Changes' }
      </PrimaryButton>
    );
  }, [
    groupPermissionsId,
    state.submitLoading,
    onSaveChangesClick,
    onCreatePermissionsClick,
  ]);

  const onGroupNameChange = React.useCallback(event => {

    setState(prevState => ({
      ...prevState,
      groupName: event.target.value,
    }));
  }, []);

  const onGroupDescriptionChange = React.useCallback(event => {

    setState(prevState => ({
      ...prevState,
      groupDescription: event.target.value,
    }));
  }, []);

  React.useEffect(() => {

    if (!groupPermissionsId || !groupPermissionsDetail) {
      return;
    }

    if (
      !!state.groupName ||
      !!state.groupDescription ||
      !!state.allowedTemplates
    ) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      groupName: groupPermissionsDetail?.group,
      groupDescription: groupPermissionsDetail?.description,
      allowedTemplates: groupPermissionsDetail?.templateIds,
    }));
  }, [
    state.groupName,
    state.groupDescription,
    state.allowedTemplates,
    groupPermissionsDetail,
    groupPermissionsId,
  ]);

  return (

    <BasePage
      heading={`Template Permissions ${(!!groupPermissionsDetail && ` / ${groupPermissionsDetail?.group || ''}`) || ''}`}
      subHeading={'Specify list of templates that this user group has access to.'}
      rightComponent={button}
      loading={templatesLoading || groupPermissionsDetailLoading}
      error={templatesError || groupPermissionsDetailError}>

      <CollapsibleCard
        header={'Basic Details'}
        sx={{
          marginBottom: 2,
        }}>

        <FlexBox
          sx={{
            marginTop: 1,
            padding: 1,
            gap: 2,
          }}>

          <FlexBox
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>

            <Paragraph
              sx={{
                flex: '1',
              }}>

              Group Name
            </Paragraph>

            <TextField
              size={'small'}
              sx={{
                flex: '4',
              }}
              value={state.groupName}
              onChange={onGroupNameChange}/>
          </FlexBox>

          <FlexBox
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>

            <Paragraph
              sx={{
                flex: '1',
              }}>

              Description
            </Paragraph>

            <TextField
              size={'small'}
              sx={{
                flex: '4',
              }}
              value={state.groupDescription}
              onChange={onGroupDescriptionChange}/>
          </FlexBox>
        </FlexBox>
      </CollapsibleCard>

      <CollapsibleCard
        header={'Templates'}>

        { /* TODO: Handle Empty state properly */ }

        <FlexBox
          sx={{
            margin: 2,
          }}>

          <TemplatesTable
            schema={TableSchema}
            cellRenderers={tableCellRenderers}
            data={tableData}
            onRowClick={onTableRowClick}/>
        </FlexBox>
      </CollapsibleCard>
    </BasePage>
  );
};

TemplatePermissionsEditor.displayName = 'TemplatePermissionsEditor';