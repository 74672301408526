import React from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import {
  Assets,
  Routes,
} from 'enums';

import {
  FlexBox,
  PageHeader,
  PrimaryButton,
  Divider,
  Caption,
  CaptionLink,
  useTheme,
  Heading2,
  useModal,
  useAuth,
  Loader,
} from 'tt-mod-frontend';

import {
  EnvironmentConfig,
} from 'config';

export const Login = () => {

  const [
    state,
    setState,
  ] = React.useState({
    loading: false,
  });

  const {
    palette,
  } = useTheme();

  const {
    login,
    getAuthCodeFromSearchParams,
    clearAuthCode,
  } = useAuth({
    clientId: EnvironmentConfig.Authentication.ClientId,
    clientSecret: EnvironmentConfig.Authentication.ClientSecret,
    issuerUrl: EnvironmentConfig.Authentication.Issuer,
    redirectUrl: EnvironmentConfig.Authentication.RedirectUrl,
  });

  const {
    showErrorModal,
  } = useModal();

  const navigate = useNavigate();

  const onLoginClick = React.useCallback(() => {

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    login(true)
      .catch(() => {

        setState(prevState => ({
          ...prevState,
          loading: false,
        }));

        showErrorModal({
          message: 'Login failed. Please try again.'
        });
      });
  }, [
    login,
    showErrorModal,
  ]);

  React.useEffect(() => {

    const authCode = getAuthCodeFromSearchParams();

    if (!authCode) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    login()
      .then(() => {

        navigate(Routes.Templates, {
          replace: true,
        });
      })
      .catch(() => {

        clearAuthCode();

        showErrorModal({
          message: 'Login failed. Please try again.'
        });
      }).finally(() => {

        setState(prevState => ({
          ...prevState,
          loading: false,
        }));
      });
  }, [
    login,
    navigate,
    clearAuthCode,
    getAuthCodeFromSearchParams,
    showErrorModal,
  ]);

  return (

    <FlexBox
      sx={{
        flex: '1',
      }}>

      <PageHeader
        logoSrc={Assets.Images.Logo}
        logoHeight={'48px'}/>

      <FlexBox
        sx={{
          flex: '1',
          justifyContent: 'center',
          alignItems: 'center',
        }}>

        <FlexBox
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: palette.divider,
            borderRadius: '8px',
            padding: 2,
            minWidth: '450px',
            minHeight: '300px',
          }}>

          <Heading2
            sx={{
              fontWeight: 'bold',
            }}>

            Login
          </Heading2>

          { !state.loading &&

            <FlexBox>

              <Caption
                sx={{
                  marginY: 2,
                }}>

                Welcome to Template Tool. Sign in below
              </Caption>

              <PrimaryButton
                sx={{
                  marginX: 'auto',
                  marginY: 2,
                  width: '320px',
                }}
                onClick={onLoginClick}>

                Continue with SSO Login
              </PrimaryButton>
            </FlexBox>
          }

          { state.loading &&

            <FlexBox
              sx={{
                marginTop: 4,
                marginBottom: 0,
              }}>

              <Loader
                message={'Logging you in...'}/>
            </FlexBox>
          }

          <FlexBox
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              marginY: 2,
            }}>

            <Divider
              color={palette.common.black}
              sx={{
                flex: '1',
              }}/>

            <Caption
              sx={{
                marginX: 2,
              }}>

              Or
            </Caption>

            <Divider
              color={palette.common.black}
              sx={{
                flex: '1',
              }}/>
          </FlexBox>

          <FlexBox
            sx={{
              flexDirection: 'row',
            }}>

            <Caption>

              New to Template Tool?
            </Caption>

            <CaptionLink
              sx={{
                marginLeft: 1,
              }}>

              Contact Us
            </CaptionLink>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

Login.displayName = 'Login';