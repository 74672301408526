import React from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import {
  Caption,
  FlexBox,
  Heading2,
  PageHeader,
  PrimaryButton,
  useTheme,
} from 'tt-mod-frontend';

import {
  Assets,
  Routes,
} from 'enums';

export const NotFoundError = () => {

  const {
    palette,
  } = useTheme();

  const navigate = useNavigate();

  const onBackClick = React.useCallback(() => {

    navigate(Routes.Templates);
  }, [
    navigate,
  ]);

  return (

    <FlexBox
      sx={{
        flex: '1',
      }}>

      <PageHeader
        logoSrc={Assets.Images.Logo}
        logoHeight={'48px'}/>

      <FlexBox
        sx={{
          flex: '1',
          justifyContent: 'center',
          alignItems: 'center',
        }}>

        <FlexBox
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: palette.divider,
            borderRadius: '8px',
            padding: 2,
            minWidth: '450px',
          }}>

          <Heading2
            sx={{
              fontWeight: 'bold',
            }}>

            404
          </Heading2>

          <Caption
            sx={{
              marginY: 2,
            }}>

            The page you are looking for could not be found.
          </Caption>

          <PrimaryButton
            sx={{
              marginX: 'auto',
              marginY: 2,
              width: '320px',
            }}
            onClick={onBackClick}>

            Back to Home Page
          </PrimaryButton>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

NotFoundError.displayName = 'NotFoundError';