import {
  useAutoRefreshApi,
} from 'tt-mod-frontend';

import {
  EnvironmentConfig,
} from 'config';

export const useAutoRefresh = () => {

  const {
    withAutoRefresh,
  } = useAutoRefreshApi({
    clientId: EnvironmentConfig.Authentication.ClientId,
    clientSecret: EnvironmentConfig.Authentication.ClientSecret,
    issuerUrl: EnvironmentConfig.Authentication.Issuer,
    redirectUrl: EnvironmentConfig.Authentication.RedirectUrl,
  });

  return withAutoRefresh;
};