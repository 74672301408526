import {
  CommonEnvironmentConfig,
} from 'tt-mod-frontend';

export const EnvironmentConfig = {
  ...CommonEnvironmentConfig,
  Authentication: {
    Issuer: 'https://auth.tech-haven.co.za/realms/master',
    ClientId: 'template-tool',
    ClientSecret: 'xCrujrB4Q2t5I3NOoIgaub70YBgM1p1d',
    RedirectUrl: window.location.origin,
  },
};