import React from 'react';

// TODO: Use admin types
import {
  ApiResponse,
  TemplateSummary,
  useUser,
} from 'tt-mod-frontend';

import {
  AdminTemplateDetail,
} from 'types';

import {
  getTemplates,
  getTemplateDetail,
  createTemplate as apiCreateTemplate,
  updateTemplate as apiUpdateTemplate,
} from 'api';

import {
  useAutoRefresh,
} from 'hooks/useAutoRefresh';

interface TemplateHookInput {
  templateId?: string,
  runApi?: {
    templates?: boolean,
    templateDetail?: boolean,
  },
};

interface TemplateHookOutput {
  templatesLoading: boolean,
  templates: TemplateSummary[]|undefined,
  templatesError: any,
  refetchTemplates: () => void,
  templateDetailLoading: boolean,
  templateDetail: AdminTemplateDetail|undefined,
  templateDetailError: any,
  refetchTemplateDetail: () => void,
  createTemplate: ({ templateData }: { templateData: any }) => Promise<ApiResponse<any>>,
  updateTemplate: ({ id, templateData }: { templateData: any, id: string }) => Promise<ApiResponse<any>>,
}

type TemplateApiHook = (input?: TemplateHookInput) => TemplateHookOutput;

export const useTemplateApi: TemplateApiHook = ({
  templateId,
  runApi,
} = {}) => {

  const [
    state,
    setState,
  ] = React.useState({
    templatesLoading: false,
    templates: undefined,
    templatesError: undefined,
    templateDetailLoading: false,
    templateDetail: undefined,
    templateDetailError: undefined,
    currentTemplateId: undefined,
  });

  const {
    tokens,
  } = useUser();

  const withAutoRefresh = useAutoRefresh();

  const fetchTemplateDetail = React.useCallback(async () => {

    setState(prevState => ({
      ...prevState,
      templateDetailLoading: true,
      templateDetail: undefined,
      templateDetailError: undefined,
      currentTemplateId: templateId,
    }));

    try {

      const response = await withAutoRefresh(getTemplateDetail)({
        id: templateId,
        tokens,
      });

      if (response.status !== 200) {

        setState(prevState => ({
          ...prevState,
          templateDetail: undefined,
          templateDetailError: response.data,
          templateDetailLoading: false,
        }));

        return;
      }

      setState(prevState => ({
        ...prevState,
        templateDetail: response.data,
        templateDetailLoading: false,
        templateDetailError: undefined,
      }));
    } catch (error) {

      setState(prevState => ({
        ...prevState,
        templateDetail: undefined,
        templateDetailLoading: false,
        templateDetailError: error,
      }));
    }
  }, [
    tokens,
    templateId,
    withAutoRefresh,
  ]);

  const fetchTemplates = React.useCallback(async () => {

    setState(prevState => ({
      ...prevState,
      templatesLoading: true,
      templates: undefined,
      templatesError: undefined,
    }));

    try {

      const response = await withAutoRefresh(getTemplates)({
        tokens,
      });

      if (response.status !== 200) {

        setState(prevState => ({
          ...prevState,
          templatesError: response.data,
          templatesLoading: false,
          templates: undefined,
        }));
      }

      setState(prevState => ({
        ...prevState,
        templatesLoading: false,
        templates: response.data,
        templateDetailError: undefined,
      }));
    } catch (error) {

      setState(prevState => ({
        ...prevState,
        templatesError: error,
        templatesLoading: false,
        templates: undefined,
      }));
    }
  }, [
    tokens,
    withAutoRefresh,
  ]);

  const createTemplate = React.useCallback(async ({
    templateData,
  }) => {

    const response = await withAutoRefresh(apiCreateTemplate)({
      templateData,
      tokens,
    });

    return response;
  }, [
    tokens,
    withAutoRefresh,
  ]);

  const updateTemplate = React.useCallback(async ({
    id,
    templateData,
  }) => {

    const response = await withAutoRefresh(apiUpdateTemplate)({
      id,
      templateData,
      tokens,
    });

    return response;
  }, [
    tokens,
    withAutoRefresh,
  ]);

  React.useEffect(() => {

    if (!runApi?.templates) {
      return;
    }

    if (!!state.templatesLoading || !!state.templatesError || !!state.templates) {
      return;
    }

    fetchTemplates();
  }, [
    runApi?.templates,
    state.templates,
    state.templatesLoading,
    state.templatesError,
    fetchTemplates,
  ]);

  React.useEffect(() => {

    if (!runApi?.templateDetail || !templateId) {
      return;
    }

    if ((state.currentTemplateId === templateId) && (!!state.templateDetailLoading || !!state.templateDetailError || !!state.templateDetail)) {
      return;
    }

    fetchTemplateDetail();
  }, [
    templateId,
    state.currentTemplateId,
    runApi?.templateDetail,
    state.templateDetail,
    state.templateDetailLoading,
    state.templateDetailError,
    fetchTemplateDetail,
  ]);

  return {
    templatesLoading: state.templatesLoading,
    templates: state.templates,
    templatesError: state.templatesError,
    refetchTemplates: fetchTemplates,
    templateDetailLoading: state.templateDetailLoading,
    templateDetail: state.templateDetail,
    templateDetailError: state.templateDetailError,
    refetchTemplateDetail: fetchTemplateDetail,
    createTemplate,
    updateTemplate,
  };
};