import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  FlexBox,
  useTheme,
} from 'tt-mod-frontend';

interface TemplateTableSchemaItem {
  key: string,
  heading: string,
};

interface TemplatesTableProps {
  schema?: TemplateTableSchemaItem[],
  data?: any[],
  cellRenderers?: {
    [key: string]: (cell: any) => React.ReactNode,
  },
  onRowClick?: (row: any) => void,
};

// TODO: Rename to something more generic
// TODO: Move into shared repo
export const TemplatesTable = ({
  schema,
  data,
  cellRenderers,
  onRowClick,
}: TemplatesTableProps) => {

  const {
    palette,
  } = useTheme();

  const headerCells = React.useMemo(() => {

    if (!Array.isArray(schema)) {
      return [];
    }

    const components = [];

    for (const item of schema) {

      components.push(

        <TableCell
          key={item.key}
          sx={{
            backgroundColor: palette.divider,
            fontWeight: 'bold',
          }}>

          {item.heading}
        </TableCell>
      );
    }

    return components;
  }, [
    schema,
    palette,
  ]);

  const rows = React.useMemo(() => {

    if (!Array.isArray(data) || !Array.isArray(schema)) {
      return [];
    }

    const components = [];

    for (const rowItem of data) {

      const cells = [];

      for (const schemaItem of schema) {

        const renderer = cellRenderers?.[schemaItem.key];

        const cellValue = typeof renderer === 'function'
          ? renderer(rowItem[schemaItem.key])
          : rowItem[schemaItem.key];

        cells.push(

          <TableCell
            key={schemaItem.key}>

            {cellValue}
          </TableCell>
        );
      }

      const onTableRowClick = () => {

        !!onRowClick && onRowClick(rowItem);
      };

      components.push(

        <TableRow
          key={rowItem.id}
          sx={{
            cursor: 'pointer',
          }}
          onClick={onTableRowClick}>

          {cells}
        </TableRow>
      );
    }

    return components;
  }, [
    schema,
    data,
    cellRenderers,
    onRowClick,
  ]);

  return (

    <TableContainer
      component={FlexBox}
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: palette.divider,
      }}>

      <Table>

        <TableHead>

          <TableRow>

            {headerCells}
          </TableRow>
        </TableHead>

        <TableBody>

          {rows}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TemplatesTable.displayName = 'TemplatesTable';