import React from 'react';

import {
  useNavigate,
  useLocation,
} from 'react-router-dom';

import {
  BoxProps,
} from '@mui/material';

import {
  useTheme,
  FlexBox,
  NavigationTree,
} from 'tt-mod-frontend';

import {
  Routes,
} from 'enums';

const StaticTreeData = [{
  category: 'Template Management',
  displayName: 'Templates',
  path: undefined,
  route: Routes.Templates,
}, {
  category: 'Template Management',
  displayName: 'Permissions',
  path: undefined,
  route: Routes.TemplatePermissions,
}];

export const PageSidebar = (props: BoxProps) => {

  const {
    palette,
  } = useTheme();

  const navigate = useNavigate();

  const location = useLocation();

  const selectedItem = React.useMemo(() => {

    for (const item of StaticTreeData) {

      if (item.route === location.pathname) {
        return item;
      }
    }

    return undefined;
  }, [
    location,
  ]);

  const onNavigationTreeItemClick = React.useCallback((item: any) => {

    navigate(item.route);
  }, [
    navigate,
  ]);

  return (

    <FlexBox
      {...props}
      sx={{
        maxWidth: '300px',
        width: '100%',
        backgroundColor: palette.grey[200],
        borderRightWidth: '1px',
        borderColor: palette.divider,
        borderStyle: 'solid',
        padding: 1,
        ...props?.sx,
      }}>

      <NavigationTree
        data={StaticTreeData}
        selectedItem={selectedItem}
        onItemClick={onNavigationTreeItemClick}/>
    </FlexBox>
  );
};

PageSidebar.displayName = 'PageSidebar';