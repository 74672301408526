import React from 'react';

import {
  createHashRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';

import {
  Routes,
} from 'enums';

import {
  RoutePages,
} from 'ui/navigation/routes';

import {
  AppMenu,
} from 'ui/app';

import {
  Login,
  NotFoundError,
} from 'ui/pages';

const routerConfig: RouteObject[] = [{
  path: Routes.Login,
  element: <Login/>,
}, {
  path: Routes.Default,
  element: <AppMenu/>,
  errorElement: <NotFoundError/>,
  children: RoutePages,
}];

const router = createHashRouter(routerConfig);

export const Router = () => <RouterProvider router={router}/>;

Router.displayName = 'Router';