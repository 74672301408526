import {
  ApiResponse,
  Tokens,
} from 'tt-mod-frontend';

import {
  GroupPermissions,
} from 'types';

import {
  EnvironmentConfig,
} from 'config';

export const getGroupPermissions = async ({
  tokens,
}: {
  tokens: Tokens,
}) => {

  const response = await fetch(`${EnvironmentConfig.ApiBase}/api/admin/group-perm`, {
    headers: {
      Authorization: `Bearer ${tokens?.access}`,
    },
    method: 'GET',
  });

  const responseData = await response.json();

  const result: ApiResponse<GroupPermissions[]> = {
    status: response.status,
    data: responseData,
  };

  return result;
};

export const getGroupPermissionsDetail = async ({
  id,
  tokens,
}: {
  id: string,
  tokens: Tokens,
}) => {

  const response = await fetch(`${EnvironmentConfig.ApiBase}/api/admin/group-perm/${id}`, {
    headers: {
      Authorization: `Bearer ${tokens?.access}`,
    },
    method: 'GET',
  });

  const responseData = await response.json();

  const result: ApiResponse<GroupPermissions> = {
    status: response.status,
    data: responseData,
  };

  return result;
};

// TODO: Types
export const createGroupPermissions = async ({
  groupPermissionsData,
  tokens,
}) => {

  const response = await fetch(`${EnvironmentConfig.ApiBase}/api/admin/group-perm`, {
    headers: {
      Authorization: `Bearer ${tokens?.access}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(groupPermissionsData),
    method: 'POST',
  });

  const responseData = await response.json();

  const result: ApiResponse<any> = {
    status: response.status,
    data: responseData,
  };

  return result;
};

export const updateGroupPermissions = async ({
  id,
  groupPermissionsData,
  tokens,
}) => {

  const response = await fetch(`${EnvironmentConfig.ApiBase}/api/admin/group-perm/${id}`, {
    headers: {
      Authorization: `Bearer ${tokens?.access}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(groupPermissionsData),
    method: 'PUT',
  });

  const responseData = await response.json();

  const result: ApiResponse<any> = {
    status: response.status,
    data: responseData,
  };

  return result;
};