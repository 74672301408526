const DropdownOptionsSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      optionTitle: {
        type: 'string',
      },
      optionValue: {
        type: ['string', 'boolean', 'integer', 'number'],
      },
    },
  },
};

const BaseSectionSchema = {
  type: 'object',
  properties: {
    sectionName: {
      type: 'string',
    },
    sectionTitle: {
      type: 'string',
    },
    sectionType: {
      enum: ['form', 'table'],
    },
  },
  required: ['sectionName', 'sectionTitle', 'sectionType'],
};

const BaseFormFieldSchema = {
  type: 'object',
  properties: {
    fieldName: {
      type: 'string',
    },
    fieldTitle: {
      type: 'string',
    },
    fieldType: {
      enum: ['text', 'number', 'dropdown', 'image', 'longtext'],
    },
    fieldDefault: {
      type: ['string', 'boolean', 'integer', 'number'],
    },
  },
  required: ['fieldName', 'fieldTitle', 'fieldType'],
};

const BaseTableColSchema = {
  type: 'object',
  properties: {
    colName: {
      type: 'string',
    },
    colTitle: {
      type: 'string',
    },
    colType: {
      enum: ['text', 'number', 'dropdown', 'image'],
    },
    colDefault: {
      type: ['string', 'boolean', 'integer', 'number'],
    },
  },
  required: ['colName', 'colTitle', 'colType'],
};

const getConditionalDropdownSchema = name => ({
  if: {
    properties: {
      [name]: {
        const: 'dropdown',
      },
    },
    required: [name],
  },
  then: {
    properties: {
      dropDownOptions: DropdownOptionsSchema,
    },
  },
});

export const TemplateContextFormatSchema = {
  type: 'array',
  items: {
    ...BaseSectionSchema,
    allOf: [{
      if: {
        properties: {
          sectionType: {
            const: 'form',
          },
        },
        required: ['sectionType'],
      },
      then: {
        properties: {
          formFields: {
            type: 'array',
            items: {
              ...BaseFormFieldSchema,
              allOf: [
                getConditionalDropdownSchema('fieldType')
              ],
            },
          },
        },
      },
    }, {
      if: {
        properties: {
          sectionType: {
            const: 'table',
          },
        },
        required: ['sectionType'],
      },
      then: {
        properties: {
          tableCols: {
            type: 'array',
            items: {
              ...BaseTableColSchema,
              allOf: [
                getConditionalDropdownSchema('colType')
              ],
            },
          },
        },
      },
    }],
  },
};