import React from 'react';

import {
  RecoilRoot,
} from 'recoil';

import {
  AppRoot,
} from 'ui/app';

import {
  Router,
} from 'ui/navigation';

import {
  UserAtom,
  SessionDataKey,
} from 'tt-mod-frontend';

export const App = () => {

  return (

    <React.StrictMode>

      <RecoilRoot
        initializeState={({ set }) => {
          try {
            const existingSession = JSON.parse(sessionStorage.getItem(SessionDataKey));

            if (existingSession) {
              set(UserAtom, existingSession);
            }
          } catch {
            sessionStorage.setItem(SessionDataKey, JSON.stringify(null));
          }
        }}>

        <AppRoot>

          <Router/>
        </AppRoot>
      </RecoilRoot>
    </React.StrictMode>
  );
};