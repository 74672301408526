import React from 'react';

import {
  Routes,
} from 'enums';

import {
  TemplateEditor,
  TemplatePermissions,
  TemplatePermissionsEditor,
  Templates,
} from 'ui/pages';

export const RoutePages = [{
  path: Routes.Templates,
  element: <Templates />,
}, {
  path: Routes.TemplateEditor,
  element: <TemplateEditor />,
}, {
  path: Routes.TemplatePermissions,
  element: <TemplatePermissions />,
}, {
  path: Routes.TemplatePermissionsEditor,
  element: <TemplatePermissionsEditor />,
}];