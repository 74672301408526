import {
  TemplateDetail,
  TemplateSummary,
  ApiResponse,
  Tokens,
} from 'tt-mod-frontend';

import {
  EnvironmentConfig,
} from 'config';

// TODO: New Type for AdminTemplateSummary
export const getTemplates = async ({
  tokens,
}: {
  tokens: Tokens,
}) => {

  const response = await fetch(`${EnvironmentConfig.ApiBase}/api/admin/template`, {
    headers: {
      Authorization: `Bearer ${tokens?.access}`,
    },
    method: 'GET',
  });

  const responseData = await response.json();

  const result: ApiResponse<TemplateSummary[]> = {
    status: response.status,
    data: responseData,
  };

  return result;
};

// TODO: New type for AdminTemplate Detail
export const getTemplateDetail = async ({
  id,
  tokens,
}: {
  id: string,
  tokens: Tokens,
}) => {

  const response = await fetch(`${EnvironmentConfig.ApiBase}/api/admin/template/${id}`, {
    headers: {
      Authorization: `Bearer ${tokens?.access}`,
    },
    method: 'GET',
  });

  const responseData = await response.json();

  const result: ApiResponse<TemplateDetail> = {
    status: response.status,
    data: responseData,
  };

  return result;
};

// TODO: Actual types
export const createTemplate = async ({
  templateData,
  tokens,
}) => {

  const response = await fetch(`${EnvironmentConfig.ApiBase}/api/admin/template`, {
    headers: {
      Authorization: `Bearer ${tokens?.access}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(templateData),
    method: 'POST',
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData?.errorMessage || `An error has occurred. Status code: ${response.status}`);
  }

  const result: ApiResponse<any> = {
    status: response.status,
    data: responseData,
  };

  return result;
};

export const updateTemplate = async ({
  id,
  templateData,
  tokens,
}) => {

  const response = await fetch(`${EnvironmentConfig.ApiBase}/api/admin/template/${id}`, {
    headers: {
      Authorization: `Bearer ${tokens?.access}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(templateData),
    method: 'PUT',
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData?.errorMessage || `An error has occurred. Status code: ${response.status}`);
  }

  const result: ApiResponse<any> = {
    status: response.status,
    data: responseData,
  };

  return result;
};