import React from 'react';

import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  jwtDecode,
} from 'jwt-decode';

import {
  FlexDirection,
  FlexBox,
  PageHeader,
  useUser,
  useAuth,
} from 'tt-mod-frontend';

import {
  Assets,
  Routes,
} from 'enums';

import {
  EnvironmentConfig,
} from 'config';

import {
  PageSidebar,
} from 'ui/components';

export const AppMenu = () => {

  const {
    pathname,
  } = useLocation();

  const {
    loggedIn,
    tokens,
  } = useUser();

  const {
    logout,
  } = useAuth({
    clientId: EnvironmentConfig.Authentication.ClientId,
    clientSecret: EnvironmentConfig.Authentication.ClientSecret,
    issuerUrl: EnvironmentConfig.Authentication.Issuer,
    redirectUrl: EnvironmentConfig.Authentication.RedirectUrl,
  });

  const navigate = useNavigate();

  const {
    userName,
    userOrg,
  } = React.useMemo(() => {

    try {

      const decodedIdToken: any = jwtDecode(tokens?.id);

      return {
        userName: decodedIdToken?.preferred_username,
        userOrg: decodedIdToken?.organization,
      };

    } catch {
      return {};
    }
  }, [
    tokens?.id,
  ]);

  React.useEffect(() => {

    if (loggedIn || pathname === Routes.Login) {
      return;
    }

    navigate(Routes.Login, {
      replace: true,
    })
  }, [
    loggedIn,
    pathname,
    navigate,
  ]);

  React.useEffect(() => {

    if (!loggedIn || pathname !== Routes.Default) {
      return;
    }

    navigate(Routes.Templates, {
      replace: true,
    });
  }, [
    loggedIn,
    pathname,
    navigate,
  ]);

  return (

    <FlexBox
      sx={{
        flex: '1',
        flexDirection: FlexDirection.Column,
        overflow: 'hidden',
      }}>

      <PageHeader
        username={userName}
        company={userOrg}
        onLogout={() => logout()}
        showUserDropdown={loggedIn}
        logoSrc={Assets.Images.Logo}
        logoutIconMargin={1}
        logoHeight={'48px'}/>

      <FlexBox
        sx={{
          flex: '1',
          flexDirection: FlexDirection.Row,
          overflow: 'hidden',
        }}>

        <PageSidebar/>

        <FlexBox
          sx={{
            flexDirection: FlexDirection.Column,
            flex: '1',
            overflow: 'auto',
          }}>

          <Outlet/>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

AppMenu.displayName = 'AppMenu';