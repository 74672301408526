import React from 'react';

import {
  useNavigate,
  generatePath,
} from 'react-router-dom';

import {
  BasePage,
  FlexBox,
  PrimaryButton,
} from 'tt-mod-frontend';

import {
  Routes,
} from 'enums';

import {
  useTemplateApi,
} from 'hooks';

import {
  TemplatesTable,
} from 'ui/components';

const TableSchema = [{
  key: 'id',
  heading: 'Id',
}, {
  key: 'name',
  heading: 'Template Name',
}, {
  key: 'description',
  heading: 'Description',
}, {
  key: 'folderPath',
  heading: 'Group Path',
}, {
  key: 'enabled',
  heading: 'Enabled',
}];

const CellRenderers = {
  enabled: enabled => (enabled && 'Yes') || 'No',
  id: id => <FlexBox sx={{ color: '#1976d2' }}>{id}</FlexBox>
};

export const Templates = () => {

  const navigate = useNavigate();

  const {
    templates,
    templatesError,
    templatesLoading,
    refetchTemplates,
  } = useTemplateApi({
    runApi: {
      templates: true,
    },
  });

  const newTemplateButton = React.useMemo(() => {

    const onNewTemplateClick = () => {
      navigate(generatePath(Routes.TemplateEditor, {
        templateId: undefined,
      }));
    };

    return (

      <PrimaryButton
        onClick={onNewTemplateClick}>

        New Template
      </PrimaryButton>
    );
  }, [
    navigate,
  ]);

  const onRowClick = React.useCallback(row => {

    navigate(generatePath(Routes.TemplateEditor, {
      templateId: row.id,
    }));
  }, [
    navigate,
  ]);

  return (

    <BasePage
      heading={'Templates'}
      subHeading={'Manage the list of templates, make changes, add new or archive unused templates.'}
      loading={templatesLoading}
      loadingText={'Loading Templates...'}
      error={templatesError}
      onErrorRetry={refetchTemplates}
      rightComponent={newTemplateButton}>

      <FlexBox
        sx={{
          flex: '1 1 0px',
          overflow: 'auto',
        }}>

        <TemplatesTable
          schema={TableSchema}
          cellRenderers={CellRenderers}
          data={templates}
          onRowClick={onRowClick}/>
      </FlexBox>
    </BasePage>
  );
};

Templates.displayName = 'Home';