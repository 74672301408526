import React from 'react';

import {
  CssBaseline,
  ThemeProvider,
} from '@mui/material';

import {
  FlexBox,
  ModalProvider,
  Themes,
  MaterialThemes,
} from 'tt-mod-frontend';

interface AppRootProps {
  children?: React.ReactNode,
}

export const AppRoot = ({
  children,
}: AppRootProps) => {

  return (

    <FlexBox
      sx={{
        position: 'absolute',
        minWidth: '100%',
        maxWidth: '100%',
        minHeight: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
      }}>

      <ThemeProvider
        theme={MaterialThemes[Themes.Light]}>

        <CssBaseline/>

        {children}

        <ModalProvider />
      </ThemeProvider>
    </FlexBox>
  );
};

AppRoot.displayName = 'AppRoot';